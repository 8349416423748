import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, vModelRadio as _vModelRadio, withDirectives as _withDirectives, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "row g-5 g-xl-8"
}
const _hoisted_2 = { class: "card-body py-3" }
const _hoisted_3 = { class: "table-responsive" }
const _hoisted_4 = { class: "table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4" }
const _hoisted_5 = { class: "fw-bolder text-muted" }
const _hoisted_6 = { class: "text-dark fw-bolder d-block fs-6" }
const _hoisted_7 = ["innerHTML"]
const _hoisted_8 = {
  key: 1,
  class: "row g-5 g-xl-8"
}
const _hoisted_9 = {
  key: 0,
  class: "row g-5 g-xl-8"
}
const _hoisted_10 = { class: "floating-progress" }
const _hoisted_11 = { class: "percentage-label" }
const _hoisted_12 = { class: "card-body py-3" }
const _hoisted_13 = { class: "table-responsive" }
const _hoisted_14 = { class: "table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4" }
const _hoisted_15 = { class: "fw-bolder text-muted" }
const _hoisted_16 = { class: "text-dark fw-bolder d-block fs-6" }
const _hoisted_17 = ["innerHTML"]
const _hoisted_18 = { class: "radio radio-outline radio-success" }
const _hoisted_19 = ["name", "value", "onUpdate:modelValue", "onChange"]
const _hoisted_20 = { class: "d-flex flex-stack pt-10" }
const _hoisted_21 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_progress = _resolveComponent("el-progress")!

  return (_ctx.isShowExample)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("table", _hoisted_4, [
              _createElementVNode("thead", null, [
                _createElementVNode("tr", _hoisted_5, [
                  _cache[2] || (_cache[2] = _createElementVNode("th", { class: "min-w-50px" }, "No", -1)),
                  _cache[3] || (_cache[3] = _createElementVNode("th", { class: "min-w-250px" }, "Soal", -1)),
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.keyList, (item, index) => {
                    return (_openBlock(), _createElementBlock("th", {
                      key: index,
                      class: "min-w-25px"
                    }, _toDisplayString(item.toUpperCase()), 1))
                  }), 128))
                ])
              ]),
              _createElementVNode("tbody", null, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.modalitasBelajarExampleCache, (item, index) => {
                  return (_openBlock(), _createElementBlock("tr", { key: index }, [
                    _createElementVNode("td", null, [
                      _createElementVNode("p", _hoisted_6, _toDisplayString(item.QuestionNumber), 1)
                    ]),
                    _createElementVNode("td", null, [
                      _createElementVNode("p", {
                        class: "text-dark fw-bolder d-block fs-6",
                        innerHTML: _ctx.formatText(item.QuestionDescription)
                      }, null, 8, _hoisted_7)
                    ]),
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.keyList, (key, index2) => {
                      return (_openBlock(), _createElementBlock("td", { key: index2 }, _cache[4] || (_cache[4] = [
                        _createElementVNode("label", { class: "radio radio-outline radio-success" }, [
                          _createElementVNode("input", {
                            class: "form-check-input",
                            type: "radio",
                            name: "accountPlan"
                          })
                        ], -1)
                      ])))
                    }), 128))
                  ]))
                }), 128))
              ])
            ])
          ])
        ]),
        _createElementVNode("button", {
          type: "button",
          class: "btn btn-lg btn-primary me-3",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.openQuestion()))
        }, "Mulai")
      ]))
    : (_openBlock(), _createElementBlock("div", _hoisted_8, [
        (_ctx.is_ended == false)
          ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
              _createElementVNode("div", _hoisted_10, [
                _createVNode(_component_el_progress, {
                  percentage: (_ctx.current_time/_ctx.max_time)*100,
                  type: "dashboard",
                  status: "success"
                }, {
                  default: _withCtx(({}) => [
                    _cache[5] || (_cache[5] = _createElementVNode("span", { class: "percentage-value" }, null, -1)),
                    _createElementVNode("span", _hoisted_11, _toDisplayString(_ctx.formattedTime()), 1)
                  ]),
                  _: 1
                }, 8, ["percentage"])
              ]),
              _createElementVNode("div", _hoisted_12, [
                _createElementVNode("div", _hoisted_13, [
                  _createElementVNode("table", _hoisted_14, [
                    _createElementVNode("thead", null, [
                      _createElementVNode("tr", _hoisted_15, [
                        _cache[6] || (_cache[6] = _createElementVNode("th", { class: "min-w-50px" }, "No", -1)),
                        _cache[7] || (_cache[7] = _createElementVNode("th", { class: "min-w-250px" }, "Soal", -1)),
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.keyList, (item, index) => {
                          return (_openBlock(), _createElementBlock("th", {
                            key: index,
                            class: "min-w-25px"
                          }, _toDisplayString(item.toUpperCase()), 1))
                        }), 128))
                      ])
                    ]),
                    _createElementVNode("tbody", null, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.modalitasBelajarCache, (item, index) => {
                        return (_openBlock(), _createElementBlock("tr", { key: index }, [
                          _createElementVNode("td", null, [
                            _createElementVNode("p", _hoisted_16, _toDisplayString(item.QuestionNumber), 1)
                          ]),
                          _createElementVNode("td", null, [
                            _createElementVNode("p", {
                              class: "text-dark fw-bolder d-block fs-6",
                              innerHTML: _ctx.formatText(item.QuestionDescription)
                            }, null, 8, _hoisted_17)
                          ]),
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.keyList, (key, index2) => {
                            return (_openBlock(), _createElementBlock("td", { key: index2 }, [
                              _createElementVNode("label", _hoisted_18, [
                                _withDirectives(_createElementVNode("input", {
                                  class: "form-check-input",
                                  type: "radio",
                                  name: 'radio_'+index+'_'+index2,
                                  value: key.toUpperCase(),
                                  "onUpdate:modelValue": ($event: any) => ((item.AnswerContent) = $event),
                                  onChange: ($event: any) => (_ctx.handleRadioChange(item))
                                }, null, 40, _hoisted_19), [
                                  [_vModelRadio, item.AnswerContent]
                                ])
                              ])
                            ]))
                          }), 128))
                        ]))
                      }), 128))
                    ])
                  ])
                ])
              ]),
              _createElementVNode("div", _hoisted_20, [
                _cache[8] || (_cache[8] = _createElementVNode("div", { class: "mr-2" }, null, -1)),
                _createElementVNode("div", null, [
                  _createElementVNode("button", {
                    type: "button",
                    class: "btn btn-lg btn-primary me-3",
                    onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.goToEnd()))
                  }, "Selesai")
                ])
              ])
            ]))
          : (_openBlock(), _createElementBlock("div", _hoisted_21, _cache[9] || (_cache[9] = [
              _createStaticVNode("<div class=\"row g-5 g-xl-8\" data-v-69ada83a><div class=\"row mb-6\" data-v-69ada83a><div class=\"col-lg-8\" data-v-69ada83a><div class=\"image-input image-input-outline\" data-kt-image-input=\"true\" style=\"background-image:url(media/avatars/blank.png);\" data-v-69ada83a><div class=\"image-input-wrapper w-800px h-850px\" style=\"background-image:url(media/misc/ty.jpg);\" data-v-69ada83a></div></div></div></div></div>", 1)
            ])))
      ]))
}